import axiosClient from 'utils/axiosClient';
import api from 'api';
import {LangType} from 'pages/Settings/Languages/Languages';
import {IAppConfigs, ISmtpConfigs} from 'context/Settings.context';
import {formData} from 'utils/helpers/formData';
import {dictionaries, dictStringTypes} from 'pages/Settings/Dictionaries/Constants';

const getMenu = async () => {
	const response = await axiosClient.get(api.SETTINGS.GET_MENU);
	return response.data;
};

const getCharts = async () => {
	const response = await axiosClient.get(api.SETTINGS.GET_CHARTS);
	return response.data;
};

const getSettingsMenu = async (params: any) => {
	const response = await axiosClient.get(api.SETTINGS.GET_SETTINGS_MENU, {params});
	return response.data;
};

const updateSettingsMenu = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.SETTINGS.GET_SETTINGS_MENU + '/' + id, data);
	return response.data;
};

const getLanguages = async (lang: LangType) => {
	const response = await axiosClient.get(api.SETTINGS.GET_TRANSLATIONS, {
		headers: {
			'Accept-Language': lang
		}
	});
	return response.data;
};

const addLanguageKey = async (data: any) => {
	const response = await axiosClient.post(api.SETTINGS.LANGS_KEY, data);
	return response.data;
};

const patchLanguageKey = async (data: any) => {
	const response = await axiosClient.patch(api.SETTINGS.LANGS_KEY, data);
	return response.data;
};

const getDictionary = async (dict: string, params?: any) => {
	const response = await axiosClient.get(api.SETTINGS.DICT + `/${dict}`, {params});
	const newData = response.data.data;

	return {
		data: /*  newData.find((el: any) => el.hy)
			? newData.sort((a: {hy: string}, b: {hy: string}) => a.hy.localeCompare(b.hy))
			: */ newData
	};
};
const getDictionaryAcademicYears = async (dict: string, params?: any) => {
	const response = await axiosClient.get(api.SETTINGS.DICT + `/${dict}`, {params});
	const newData = response.data.data;

	return {
		data: /* (newData.find((el: any) => el.hy)
			? newData.sort((a: {hy: string}, b: {hy: string}) => a.hy.localeCompare(b.hy))
			: 
		) */ newData.map((el: any) => ({
			...el,
			academicYearStart: el.name.split('-')[0],
			academicYearEnd: el.name.split('-')[1]
		}))
	};
};

const deleteDictionaryItem = async (dict: string, id: number) => {
	const response = await axiosClient.delete(api.SETTINGS.DICT + `/${dict}/${id}`);
	return response.data;
};

const addDictionaryItem = async (dict: string, data: any) => {
	const response = await axiosClient.post(api.SETTINGS.DICT + `/${dict}`, data);
	return response.data;
};

const updateDictionaryItem = async (dict: string, data: any, id: number) => {
	const response = await axiosClient.patch(api.SETTINGS.DICT + `/${dict}/${id}`, data);
	return response.data;
};

interface DictionaryType {
	id: number;
	name: string;
	status: number;
	isTrigger: 0 | 1;
	creationDate: string;
	lastModifiedDate: string;
}

export const dictionaryChangePath: {[key in dictStringTypes]?: dictStringTypes} = {modules: 'subjects'};

const fetchOptions = async (dictionary: dictStringTypes, params: any = {}): Promise<DictionaryType[]> => {
	if (dictionary === 'subjects') {
		params.isModule = false;
	}
	if (dictionary === 'modules') {
		params.isModule = true;
	}
	if (dictionary === 'curriculumAcademicYears') {
		params.sortField = 'academicYearStart';
	}
	const response = await axiosClient.get(
		api.SETTINGS.DICT + `/${dictionaryChangePath[dictionary] ?? dictionary}`,
		{params}
	);

	const newData = response.data.data;

	return params?.sortField
		? newData
		: newData.find((el: any) => el.hy)
		? newData.sort((a: {hy: string}, b: {hy: string}) => a.hy.localeCompare(b.hy))
		: newData;
};

const getByCustomPath = async (path: string, fetchParams: any): Promise<any> => {
	const response = await axiosClient.get(path, {params: fetchParams});
	return response.data.data || response.data;
};

/*
 *** Admission
 */
const getAdmission = async (data?: any) => {
	const response = await axiosClient.get(api.SETTINGS.ADMISSION, {params: data});
	return response.data;
};

const createAdmission = async (data: any) => {
	const response = await axiosClient.post(api.SETTINGS.ADMISSION, data);
	return response.data;
};

const deleteAdmission = async (id: number) => {
	const response = await axiosClient.delete(api.SETTINGS.ADMISSION + '/' + id);
	return response.data;
};

const updateProfile = async (data: any) => {
	const response = await axiosClient.patch(api.SETTINGS.UPDATE_PROFILE, data);
	return response.data;
};

const updatePassword = async (data: any) => {
	const response = await axiosClient.patch(api.SETTINGS.UPDATE_PASSWORD, data);
	return response.data;
};

/*
 *** Appealing Range
 */

const getAppealingsRange = async (params?: any) => {
	const response = await axiosClient.get(api.SETTINGS.APPEALING_RANGE, {params});
	return response.data;
};

const createAppealingsRange = async (data: any) => {
	const response = await axiosClient.post(api.SETTINGS.APPEALING_RANGE, data);
	return response.data;
};

const deleteAppealingsRange = async (id: number) => {
	const response = await axiosClient.delete(api.SETTINGS.APPEALING_RANGE + '/' + id);
	return response.data;
};
/*
 *** Appealing Range
 */

const getExaminationRange = async (params?: any) => {
	const response = await axiosClient.get(api.SETTINGS.EXAMINATION_RANGE, {params});
	return response.data;
};

const createExaminationRange = async (data: any) => {
	const response = await axiosClient.post(api.SETTINGS.EXAMINATION_RANGE, data);
	return response.data;
};

const deleteExaminationRange = async (id: number) => {
	const response = await axiosClient.delete(api.SETTINGS.EXAMINATION_RANGE + '/' + id);
	return response.data;
};

/*
 *** Diploma work
 */
const getDiplomaWorksRanges = async (data?: any) => {
	const response = await axiosClient.get(api.SETTINGS.DIPLOMA_WORK_RANGE, {params: data});
	return response.data;
};

const createDiplomaWorksRange = async (data: any) => {
	const response = await axiosClient.post(api.SETTINGS.DIPLOMA_WORK_RANGE, data);
	return response.data;
};

const updateDiplomaWorksRange = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.SETTINGS.DIPLOMA_WORK_RANGE + '/' + id, data);
	return response.data;
};

const deleteDiplomaWorksRanges = async (ids: number[]) => {
	const response = await axiosClient.delete(api.SETTINGS.DIPLOMA_WORK_RANGE + '/' + ids);
	return response.data;
};

/*
 *** Configs
 */
const getAppPublicConfigs = async () => {
	const response = await axiosClient.get(api.SETTINGS.APP_PUBLIC_CONFIGS);
	return response.data;
};

const getAppConfigs = async () => {
	const response = await axiosClient.get(api.SETTINGS.APP_CONFIGS);
	return response.data;
};

const updateAppConfigs = async (data: Partial<IAppConfigs>) => {
	const response = await axiosClient.patch(api.SETTINGS.APP_CONFIGS, data);
	return response.data;
};

const getMailConfigs = async () => {
	const response = await axiosClient.get(api.SETTINGS.MAIL_CONFIGS);
	return response.data;
};

const updateMailConfigs = async (data: Partial<ISmtpConfigs>) => {
	const response = await axiosClient.patch(api.SETTINGS.MAIL_CONFIGS, data);
	return response.data;
};

const getFilesPublicConfigs = async () => {
	const response = await axiosClient.get(api.SETTINGS.FILES_PUBLIC_CONFIGS);
	return response.data;
};

const getFilesConfigs = async () => {
	const response = await axiosClient.get(api.SETTINGS.FILES_CONFIGS, {params: {pagination: 0}});
	return response.data;
};

const updateFilesConfigs = async (data: {typeID: number; path: string}) => {
	const response = await axiosClient.post(api.SETTINGS.FILES_CONFIGS, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deleteFilesConfigs = async (id: number) => {
	const response = await axiosClient.delete(api.SETTINGS.FILES_CONFIGS + '/' + id);
	return response.data;
};

/* Guide */

const getGuide = async (data?: any) => {
	const response = await axiosClient.get(api.SETTINGS.GUIDE, {params: data});
	return response.data;
};

const getGuideByID = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.SETTINGS.GUIDE + `/${id}`, {params: data});
	return response.data;
};

const createGuide = async (data?: any) => {
	const response = await axiosClient.post(api.SETTINGS.GUIDE, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const updateGuide = async (id: number, data?: any) => {
	const response = await axiosClient.patch(api.SETTINGS.GUIDE + `/${id}`, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deleteGuide = async (ids: number[]) => {
	const response = await axiosClient.delete(api.SETTINGS.GUIDE + `/${ids}`);
	return response.data;
};

//FILE
const deleteFile = async (path: string, fileName: string | number) => {
	const response = await axiosClient.delete(path + `${fileName}`);
	return response.data;
};

const service = {
	getMenu,
	getAppealingsRange,
	createAppealingsRange,
	deleteAppealingsRange,
	getExaminationRange,
	createExaminationRange,
	deleteExaminationRange,
	getSettingsMenu,
	updateSettingsMenu,
	getDictionary,
	getDictionaryAcademicYears,
	deleteDictionaryItem,
	addDictionaryItem,
	updateDictionaryItem,
	getLanguages,
	addLanguageKey,
	patchLanguageKey,
	fetchOptions,
	getByCustomPath,
	getAdmission,
	createAdmission,
	deleteAdmission,
	updateProfile,
	updatePassword,
	getDiplomaWorksRanges,
	createDiplomaWorksRange,
	updateDiplomaWorksRange,
	deleteDiplomaWorksRanges,
	getCharts,
	getAppConfigs,
	updateAppConfigs,
	getMailConfigs,
	updateMailConfigs,
	getFilesConfigs,
	updateFilesConfigs,
	deleteFilesConfigs,
	getFilesPublicConfigs,
	getAppPublicConfigs,
	getGuide,
	getGuideByID,
	updateGuide,
	createGuide,
	deleteGuide,
	deleteFile
};

export default service;
